import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { ValidateInputs } from "../helper/helper";
import Altcha from "../components/Altcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import API from "../helper/AxiosInterceptor";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPhone } from '@fortawesome/free-solid-svg-icons'; // Replace faCoffee with your desired icon

const Inquiry = () => {
  const [existEmailMsg, setExistEmailMsg] = useState("");

  const altchaRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    platformName: "",
    platformCategory: "",
    countryCode: "",
    contactNumber: "",
    email: "",
    location: "",
    description: "",
  });
  const [errors, setErrors] = useState({});

  //live location
  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          fetchPlaceName(lat, lng);
        },
        (error) => {
          console.error("Error getting location:", error);
          // toast.error("Unable to fetch your location.");
        }
      );
    } else {
      toast.error("Geolocation is not supported by this browser.");
    }
  };

  const fetchPlaceName = async (lat, lng) => {
    try {
      const apiKey = "AIzaSyB4ykam5vJ8x6JvGol0NDDiUM25ORR1VtE"; // API key
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
      const response = await API.get(url);
      if (response.data.status === "OK") {
        const placeName = response.data.results[0].formatted_address;
        setFormData({
          ...formData,
          location: placeName,
        });
      } else {
        toast.error("Unable to fetch place name.");
      }
    } catch (error) {
      console.error("Error fetching place name:", error);
      toast.error("Error fetching place name.");
    }
  };

  const handlePhoneChange = (value, country) => {
    console.log("Phone Input Debug: ", value, country);

    const dialCode = country.dialCode ? `+${country.dialCode}` : ""; // Country code prefixed with "+"
    const rawNumber = value.replace(country.dialCode, "").trim(); // Extract the raw number

    console.log("dialCode: ", dialCode, rawNumber);

    setFormData((prevData) => ({
      ...prevData,
      countryCode: dialCode, // Update the country code
      contactNumber: rawNumber, // Update the contact number
    }));
  };

  const handlePhoneBlur = () => {
    if (!formData.contactNumber) {
      setFormData((prevData) => ({
        ...prevData,
        countryCode: "",
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    const updatedInputs = ValidateInputs(name, value, errors);
    setErrors(updatedInputs);
  };

  const validate = () => {
    const newErrors = {};
    console.log("formData is here : ", formData);

    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    } else if (!nameRegex.test(formData.name)) {
      newErrors.name = "Name must be alphabetic";
    }

    // platformName validation
    if (!formData.platformName.trim()) {
      newErrors.platformName = "Platform name is required";
    }

    // platformCategory validation
    if (!formData.platformCategory.trim()) {
      newErrors.platformCategory = "Platform category is required";
    }

    if (!formData.contactNumber) {
      newErrors.contactNumber = "Contact number is required";
    } else if (formData.contactNumber.length < 10) {
      newErrors.contactNumber = "Contact number is invalid.";
    }

    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    // location validation
    if (!formData.location.trim()) {
      newErrors.location = "Location is required";
    }

    // Description validation
    if (!formData.description.trim()) {
      newErrors.description = "Description is required";
    }
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Altcha payload:", altchaRef.current?.value);

    const formErrors = validate();
    if (Object.keys(formErrors).length === 0) {
      try {
        const response = await API.post(
          `${process.env.REACT_APP_API_URL}/inquiry/add`,
          formData
        );
        console.log("Inquiry submitted successfully:", response.data);
        toast.success("Inquiry submitted successfully!");

        setFormData({
          name: "",
          platformName: "",
          countryCode: "",
          platformCategory: "",
          contactNumber: "",
          email: "",
          location: "",
          description: "",
        });
        setErrors("");
        setExistEmailMsg("");
      } catch (error) {
        console.log(
          "Error submitting : ",
          error,
          error?.response?.data?.message[0]
        );
        toast.error(
          error?.response?.data?.message || error?.response?.data?.message[0]
        );

        // setExistEmailMsg("This email is already exists");
        // console.error("Error submitting form:", error);
      }
    } else {
      setErrors(formErrors);
    }
  };

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>Inquire Now</h1>
            <ul>
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/inquire-now">&rarr; Inquire Now</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="inquire-bg section-padding light-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="inquire-image-box">
                  <img src="images/inquire-image1.png" alt="inquire" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="inquire-des">
                  <h3>Get the best services from our platform</h3>
                  <form onSubmit={handleSubmit}>
                    <div className="inquire-form-box">
                      <div className="form-group">
                        <label>Name</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-user" />
                          </div>
                          <input
                            name="name"
                            type="text"
                            className="form-control"
                            placeholder="Enter Name"
                            value={formData.name}
                            onChange={handleChange}
                            maxLength={50}
                          />
                          {errors.name && (
                            <span className="error">{errors.name}</span>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Platform Name</label>
                            <div className="form-group-inner">
                              <div className="fild-icon">
                                <i className="ti ti-building-arch" />
                              </div>
                              <input
                                name="platformName"
                                type="text"
                                className="form-control"
                                placeholder="Enter Platform Name"
                                value={formData.platformName}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              {errors.platformName && (
                                <span className="error">
                                  {errors.platformName}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Platform category</label>
                            <div className="form-group-inner">
                              <div className="fild-icon">
                                <i className="ti ti-category" />
                              </div>
                              <input
                                name="platformCategory"
                                type="text"
                                className="form-control"
                                placeholder="Enter Platform category"
                                value={formData.platformCategory}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              {errors.platformCategory && (
                                <span className="error">
                                  {errors.platformCategory}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className="form-group"
                            style={{
                              marginTop: "-20px",
                            }}
                          >
                            <label>Contact Number</label>
                            <div className="form-group-inner">
                              <PhoneInput
                                country=""
                                enableSearch={true}
                                value={
                                  formData.countryCode + formData.contactNumber
                                }
                                placeholder="Enter Contact Number"
                                onKeyDown={(event) => {
                                  if (
                                    event.key == "Backspace" &&
                                    formData?.contactNumber == ""
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onChange={(value, country) =>
                                  handlePhoneChange(value, country)
                                }
                                onBlur={handlePhoneBlur}
                                inputStyle={{
                                  width: "100%",
                                  paddingLeft: "50px",
                                  paddingRight: "15px",
                                  borderRadius: "5px",
                                  height: "43px",
                                  border: "1px solid #ccc",
                                  fontSize: "14px",
                                }}
                              />

                              {errors.contactNumber && (
                                <span className="error">
                                  {errors.contactNumber}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Email</label>
                            <div className="form-group-inner">
                              <div className="fild-icon">
                                <i className="ti ti-mail" />
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                maxLength={50}
                              />
                              {errors.email && (
                                <span className="error">{errors.email}</span>
                              )}
                              <span className="error">{existEmailMsg}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Location</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-map-pin" />
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Location"
                            name="location"
                            value={formData.location}
                            onChange={handleChange}
                            // readOnly
                          />
                          {errors.location && (
                            <span className="error">{errors.location}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Description</label>
                        <div className="form-group-inner">
                          <div className="fild-icon">
                            <i className="ti ti-info-circle" />
                          </div>
                          <textarea
                            className="form-control"
                            placeholder="Enter Description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            maxLength={500}
                          />
                          {errors.description && (
                            <span className="error">{errors.description}</span>
                          )}
                        </div>
                      </div>
                      <div>
                        <Altcha ref={altchaRef} />
                      </div>
                      <div className="form-submit-btn">
                        <button className="btn">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};
// mm

export default Inquiry;
