import { toast } from "react-toastify";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import {
  BNB_CHAIN_ID,
  BTC_CHAIN_ID,
  ETH_CHAIN_ID,
  POLYGON_CHAIN_ID,
  TESTNET_BINANCE_USDC_ADDRESS,
  TESTNET_BINANCE_USDT_ADDRESS,
  TESTNET_BINANCE_WBNB_ADDRESS,
  TESTNET_BINANCE_WBTC_ADDRESS,
  TESTNET_BINANCE_WETH_ADDRESS,
  TRON_CHAIN_ID,
} from "./constant";

// export const formatNumber = (input, decimalPlace) => {
//   if (!(input && decimalPlace)) {
//     return 0;
//   }
//   if (input) {
//     console.log("input : *------", input?.toFixed(10));
//   }

//   let str = input?.toString();
//   let dotIndex = str.indexOf(".");
//   if (dotIndex === -1) {
//     return str;
//   }
//   let preDot = str.substring(0, dotIndex + 1);
//   let postDot = str.substring(dotIndex + 1, dotIndex + decimalPlace + 1);

//   return preDot + postDot;
// };

export const formatNumber = (input, decimalPlace) => {
  if (!input || decimalPlace === undefined) {
    return "0"; // Return string "0" to maintain consistency
  }

  // Ensure input is a number and avoid scientific notation
  let num = Number(input);
  if (isNaN(num)) return "0";

  // Convert to a fixed decimal format
  let fixedNum = num.toFixed(decimalPlace);

  return fixedNum;
};

export const getListErrors = (error) => {
  if (Array.isArray(error) && error.length > 0) {
    return error[0]; // Return the first element if `error` is a non-empty array
  }
  return error; // Return the entire `error` otherwise
};

export const trimAddress = (address, firstChar, lastChar) => {
  if (!address || typeof address !== "string") {
    return "";
  }

  if (address.length <= firstChar + lastChar) {
    return address;
  } else {
    return address.slice(0, firstChar) + "..." + address.slice(-lastChar);
  }
};

export const formatDate = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, year, hours, and minutes
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date in "DD-Mon-YYYY hh:mmAM/PM" format
  const formattedDate = `${day}-${monthNames[monthIndex]}-${year} ${
    hours % 12 || 12
  }:${minutes < 10 ? "0" + minutes : minutes}${hours >= 12 ? " PM" : " AM"}`;

  return formattedDate;
};

export const ValidateInputs = (name, value, errors) => {
  const newErrors = { ...errors };

  switch (name) {
    case "name":
      newErrors.name = !value.trim() ? "Name is required" : "";
      break;
    case "email":
      newErrors.email = !value.trim()
        ? "Email is required"
        : !/\S+@\S+\.\S+/.test(value)
        ? "Email is invalid"
        : "";
      break;
    case "contactNumber":
      newErrors.contactNumber = !value
        ? "Contact number is required"
        : !/^\d{10}$/.test(value)
        ? "Contact number is invalid"
        : "";
      break;
    case "platformName":
      newErrors.platformName = !value.trim() ? "Platform name is required" : "";
      break;
    case "platformCategory":
      newErrors.platformCategory = !value.trim()
        ? "Platform category is required"
        : "";
      break;
    case "location":
      newErrors.location = !value.trim() ? "Location is required" : "";
      break;
    case "description":
      newErrors.description = !value.trim() ? "Description is required" : "";
      break;
    case "uniqueID":
      newErrors.uniqueID = !value ? "Unique ID is required" : "";
      break;
    case "question":
      newErrors.question = !value.trim() ? "Question is required" : "";
      break;
    case "answer":
      newErrors.answer = !value.trim() ? "Answer is required" : "";
      break;
    case "password":
      newErrors.password = !value ? "Password is required" : "";
      break;
    case "appName":
      newErrors.appName = !value.trim() ? "App Name is required" : "";
      break;
    case "appDescription":
      newErrors.appDescription = !value.trim() ? "Description is required" : "";
      break;
    default:
      break;
  }

  return newErrors;
};

export const copyToClipboard = (address) => {
  if (address) {
    navigator?.clipboard
      ?.writeText(address)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((error) => {
        toast.error("Failed to copy address.");
        console.error("Error copying to clipboard:", error);
      });
  } else {
    toast.warning("No address available to copy.");
  }
};

export const exportToPDF = (data) => {
  const doc = new jsPDF();

  autoTable(doc, {
    head: [
      [
        "S.N.",
        "ChainId",
        "Hash",
        "FromAddress",
        "ToAddress",
        "ReceivedAmount",
        "Timestamp",
      ],
    ],
    body: data.map((user, index) => [
      index + 1,
      user?.chainId || "",
      trimAddress(user?.hash, 6, 4) || "",
      trimAddress(user?.fromAddress, 6, 4) || "",
      trimAddress(user?.toAddress, 6, 4) || "",
      `${user?.recivedAmount} ${user?.symbol}` || "",
      formatDate(user?.createdAt) || "",
    ]),
    headStyles: { halign: "center" }, // Center align the headings
    styles: { halign: "center" }, // Center align the body
  });

  doc.save("users.pdf");
};

export const exportDepositHistoryToPDF = (data) => {
  const doc = new jsPDF();

  autoTable(doc, {
    head: [
      [
        "S.N.",
        "ChainId",
        "Transaction Hash",
        "From",
        "To",
        "Amount",
        "Received",
        "Code",
        "Timestamp",
        "Status",
      ],
    ],
    body: data.map((user, index) => [
      index + 1,
      user?.chainId || "",
      trimAddress(user?.hash, 4, 2) ? trimAddress(user?.hash, 4, 2) : "----",
      trimAddress(user?.fromAddress, 4, 2)
        ? trimAddress(user?.fromAddress, 4, 2)
        : "----",
      trimAddress(user?.toAddress, 4, 2)
        ? trimAddress(user?.toAddress, 4, 2)
        : "----",
      `${user?.amount} ${user?.symbol}` || "",
      `${user?.recivedAmount ?? 0} ${user?.symbol}` || "",
      user?.code || "",
      formatDate(user?.createdAt) || "",
      user?.status.slice(0, 7) || "",
    ]),
    margin: { top: 5, right: 5, bottom: 5, left: 5 },
    headStyles: { halign: "center" }, // Center align the headings
    styles: { halign: "center" }, // Center align the body
  });

  doc.save("users.pdf");
};

export const exportToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(
    data.map((user, index) => ({
      S_N: index + 1,
      Name: user?.name || "",
      Email: user?.email || "",
      PlatformName: user?.platformName || "",
      PlatformCategory: user?.platformCategory || "",
      ContactNumber: user?.contactNumber || "",
      Location: user?.location || "",
      Description: user?.description || "",
    }))
  );
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Users");
  XLSX.writeFile(workbook, "users.xlsx");
};

export const successSwal = (title, msg) => {
  Swal.fire({
    position: "center",
    icon: "success",
    title: title,
    text: msg,
    showConfirmButton: false,
    timer: 2500,
  });
};

export const errorSwal = (title, msg) => {
  Swal.fire({
    position: "center",
    icon: "error",
    title: title,
    text: msg,
    showConfirmButton: false,
    timer: 2500,
  });
};

export const getSwapContractAddresses = (chainId) => {
  switch (chainId.toString()) {
    case ETH_CHAIN_ID:
      return {
        router: "",
        factory: "",
      };
    case POLYGON_CHAIN_ID:
      return {
        router: "",
        factory: "",
      };
    case BNB_CHAIN_ID:
      return {
        router: "0x9ac64cc6e4415144c455bd8e4837fea55603e5c3",
        factory: "0xB7926C0430Afb07AA7DEfDE6DA862aE0Bde767bc",
        WETH: "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
      };

    case BTC_CHAIN_ID:
      return {
        router: "",
        factory: "",
      };
    case TRON_CHAIN_ID:
      return {
        router: "",
        factory: "",
      };
    default:
      throw new Error("Unsupported chainId");
  }
};

export const getTokenContractAddresses = (chainId) => {
  switch (chainId.toString()) {
    case ETH_CHAIN_ID:
      return [
        {
          name: "Select Crypto",
          value: "",
        },
      ];
    case POLYGON_CHAIN_ID:
      return [
        {
          name: "Select Crypto",
          value: "",
        },
      ];
    case BNB_CHAIN_ID:
      return [
        {
          name: "Select Crypto",
          value: "",
        },
        {
          name: "USDT",
          value: TESTNET_BINANCE_USDT_ADDRESS,
        },
        {
          name: "USDC",
          value: TESTNET_BINANCE_USDC_ADDRESS,
        },
        {
          name: "BNB",
          value: TESTNET_BINANCE_WBNB_ADDRESS,
        },
        {
          name: "ETH",
          value: TESTNET_BINANCE_WETH_ADDRESS,
        },
        {
          name: "BTC",
          value: TESTNET_BINANCE_WBTC_ADDRESS,
        },
      ];

    case BTC_CHAIN_ID:
      return [
        {
          name: "Select Crypto",
          value: "",
        },
      ];
    case TRON_CHAIN_ID:
      return [
        {
          name: "Select Crypto",
          value: "",
        },
      ];
    default:
      throw new Error("Unsupported chainId");
  }
};
