import axios from "axios";
import { NATIVE } from "./evmHelper";
import API from "./AxiosInterceptor";

export const getTronNativeBalance = async (address, tokenAddress) => {
  try {
    console.log("8955562054 : ", {
      address: address,
      tokenAddress: tokenAddress,
    });

    const url = `${process.env.REACT_APP_API_URL}/moralis-tx/tron-balance`;
    const balance = await API.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        address: address,
        tokenAddress: tokenAddress,
      },
    });

    if (tokenAddress === NATIVE) {
      const balanceOfTron = balance.data;
      return balanceOfTron;
    } else {
      const balanceOfTRC20 = balance.data;
      for (let obj of balanceOfTRC20) {
        const fetchBalanceOfTRC20 = await obj.balance;
        return fetchBalanceOfTRC20;
      }
    }
  } catch (error) {
    console.error("Error getting Tron native balance:", error);
    return 0;
  }
};

export const transferTronFunds = async (
  tokenContractAddress,
  senderAddress,
  receiverAddress,
  amount,
  decimal
) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/moralis-tx/transfer-tron`;
    const getTransaction = await API.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: {
        tokenContractAddress: tokenContractAddress,
        senderAddress: senderAddress,
        receiverAddress: receiverAddress,
        amount: amount,
        decimal: decimal,
      },
    });

    if (getTransaction?.data?.transactions) {
      return getTransaction?.data?.transactions;
    }
  } catch (error) {
    console.error("Error transferring Tron native balance:", error);
    return 0;
  }
};

export async function getValidateTronAddress(address) {
  try {
    // Make the async call to the API for Tron address validation
    const url = `${process.env.REACT_APP_API_URL}/token/validate-tron-address`;
    const response = await API.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      params: { address: address },
    });

    return response?.data?.isValid;
  } catch (error) {
    return false;
  }
}
