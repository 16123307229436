import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Footer from "../components/Footer";
import Header from "../components/Header";
import API from "../helper/AxiosInterceptor";

const ResetPassword = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [verificationToken, setVerificationToken] = useState("");

  const location = useLocation();

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      setVerificationToken(token);
    }
  }, [location.search]);

  const validateForm = () => {
    const errors = {};
    if (!newPassword) {
      errors.newPassword = "New password cannot be empty.";
    } else if (!confirmPassword) {
      errors.confirmPassword = "Confirm password cannot be empty.";
    } else if (newPassword.length < 6) {
      errors.newPassword = "Password must be at least 6 characters long.";
    } else if (
      !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+|}{":?><,./;'[\]\\`~]).{6,}$/.test(
        newPassword
      )
    ) {
      errors.newPassword =
        "Password must contain at least one uppercase letter, one lowercase letter, one special character and one number.";
    } else if (newPassword !== confirmPassword) {
      errors.confirmPassword = "Passwords do not match.";
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    validateForm();
  }, [newPassword, confirmPassword]);

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      await API.post(
        `${process.env.REACT_APP_API_URL}/merchants/reset-password`,
        {
          verificationToken: verificationToken,
          newPassword: newPassword,
          confirmPassword: confirmPassword,
        }
      )
        .then((response) => {
          toast.success("Password reset successfully!");
          navigate("/login");
        })
        .catch((error) => {
          console.error("Error resetting password:", error);
          toast.error("Failed to reset password.");
        });
    } catch (error) {
      console.log("catch error", error);
      toast.error("An unexpected error occurred.");
    }
  };

  //show password
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  return (
    <>
      <Header />
      <div className="site-bg">
        <div className="inner-page-warper section-padding">
          <div className="container">
            <div className="form-box">
              <h2>Reset Password</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-box-inner white-bg">
                  <div className="form-group">
                    <label>New Password</label>
                    <div className="form-group-inner password-fild-box">
                      <div className="fild-icon">
                        <i className="ti ti-lock" />
                      </div>
                      <input
                        type={passwordVisible ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter Password"
                        name="newPassword"
                        value={newPassword}
                        onChange={handleNewPassword}
                      />
                      {errors.newPassword && (
                        <small className="form-text text-danger">
                          {errors.newPassword}
                        </small>
                      )}

                      <div
                        className="eye-view-btn"
                        onClick={togglePasswordVisibility}
                      >
                        <i
                          className={`ti ${
                            passwordVisible ? "ti-eye-off" : "ti-eye"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Confirm New Password</label>
                    <div className="form-group-inner password-fild-box">
                      <div className="fild-icon">
                        <i className="ti ti-lock" />
                      </div>
                      <input
                        type={confirmPasswordVisible ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter Password"
                        name="confirmPassword"
                        value={confirmPassword}
                        onChange={handleConfirmPassword}
                      />
                      {errors.confirmPassword && (
                        <small className="form-text text-danger">
                          {errors.confirmPassword}
                        </small>
                      )}

                      <div
                        className="eye-view-btn"
                        onClick={toggleConfirmPasswordVisibility}
                      >
                        <i
                          className={`ti ${
                            confirmPasswordVisible ? "ti-eye-off" : "ti-eye"
                          }`}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-submit-btn">
                    <button className="btn">Submit</button>
                  </div>
                </div>
              </form>
              <div className="form-shape-box1">
                <img src="images/shape1.png" alt="shape" />
              </div>
              <div className="form-shape-box2">
                <img src="images/shape2.png" alt="shape" />
              </div>
              <div className="form-shape-box3">
                <img src="images/shape3.png" alt="shape" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ResetPassword;
