import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Loader } from "../components/Loader";
import API from "../helper/AxiosInterceptor";

const TermsConditions = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const fetchPages = async () => {
    try {
      setIsLoading(true);
      await API.get(`${process.env.REACT_APP_API_URL}/other-pages/slug`, {
        params: { slug: "terms-conditions" },
      })
        .then((res) => {
          console.log("res", res);
          setTitle(res?.data?.page?.title);
          setDescription(res?.data?.page?.description);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPages();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>{title}</h1>
            <ul>
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/terms-conditions">
                  &rarr; Terms &amp; Conditions{" "}
                </Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="our-story-bg section-padding light-bg">
          <div className="container">
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default TermsConditions;
