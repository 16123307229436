import React, { useState, useEffect, useCallback } from "react";
import DashboardHeader from "../../components/auth/DashboardHeader";
import Sidebar from "../../components/auth/Sidebar";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { evmCryptoBalanceCheck } from "../../helper/evmHelper";
import { errorSwal, formatNumber } from "../../helper/helper";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";
import { BTC_CHAIN_ID, cgHeaders, TRON_CHAIN_ID } from "../../helper/constant";
import API from "../../helper/AxiosInterceptor";

const AppsDetail = () => {
  const [appInfo, setAppInfo] = useState("");
  const { appId } = useParams();
  const [tokens, setTokens] = useState([]);
  const [updatedTokens, setUpdatedTokens] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [priceIn, setPriceIn] = useState("USD");
  const [price, setPrice] = useState({
    BTC: 0,
    ETH: 0,
    WBNB: 0,
    BNB: 0,
    USDT: 0,
    USDC: 0,
    AVAX: 0,
    MATIC: 0,
    TRX: 0,
  });
  const currencyList = [
    { name: "USD", value: "USD" },
    { name: "INR", value: "INR" },
    { name: "AUD", value: "AUD" },
  ];
  const [publicKey, setPublicKey] = useState("");
  const [privateKey, setPrivateKey] = useState("");

  async function getPrice(token) {
    switch (token.toUpperCase()) {
      case "BTC":
        return price.BTC;
      case "ETH":
        return price.ETH;
      case "BNB":
      case "WBNB":
        return price.BNB;
      case "USDT":
        return price.USDT;
      case "USDC":
        return price.USDC;
      case "AVAX":
        return price.AVAX;
      case "MATIC":
        return price.MATIC;
      case "TRX":
        return price.TRX;
      default:
        console.log("Unknown token: " + token);
        return 0;
    }
  }

  console.log("RR price : ", price);
  console.log("tokens : ", tokens);

  const fetchAppDetails = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await API.get(
        `${process.env.REACT_APP_API_URL}/apps/getById`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: { appId },
        }
      );
      if (response?.data) {
        console.log("response of app", response?.data);
        setAppInfo(response?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching app details:", error);
    } finally {
      setIsLoading(false);
    }
  }, [appId]);

  const getCryptoPrice = useCallback(async () => {
    try {
      const currencyValues = currencyList
        .map((currency) => currency.value)
        .join(",");

      const url = `${process.env.REACT_APP_COINGECKO_PRO_URL}/api/v3/simple/price?ids=bitcoin,ethereum,matic-network,tether,usd-coin,binancecoin,avalanche-2,tron&vs_currencies=${currencyValues}`;
      const response = await API.get(url, {
        headers: cgHeaders,
      });

      console.log("response ------------ 99 : ", response);

      setPrice({
        BTC: response?.data?.bitcoin[priceIn.toLowerCase()],
        ETH: response?.data?.ethereum[priceIn.toLowerCase()],
        BNB: response?.data?.binancecoin[priceIn.toLowerCase()],
        WBNB: response?.data?.binancecoin[priceIn.toLowerCase()],
        USDT: response?.data?.tether[priceIn.toLowerCase()],
        USDC: response?.data?.["usd-coin"][priceIn.toLowerCase()],
        MATIC: response?.data?.["matic-network"][priceIn.toLowerCase()],
        AVAX: response?.data?.["avalanche-2"][priceIn.toLowerCase()],
        TRX: response?.data?.tron[priceIn.toLowerCase()],
      });
    } catch (error) {
      console.error("Error fetching crypto price:", error);
    }
  }, [priceIn]);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 1000); // 1 second debounce delay

    return () => {
      clearTimeout(handler); // Clear the timeout if searchQuery changes before 1 second
    };
  }, [searchQuery]);

  const fetchTokens = useCallback(async () => {
    setIsLoading(true);
    await getCryptoPrice();
    try {
      const response = await API.get(
        `${process.env.REACT_APP_API_URL}/token/list`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            pageNo: currentPage,
            limitVal: 12,
            search: searchQuery,
          },
        }
      );
      const tokens = response?.data?.data;
      setTokens(tokens);
      console.log("tokens", tokens);
      setCurrentPage(response?.data?.currentPage);
      setTotalPages(response?.data?.totalPages);
    } catch (error) {
      console.error("Error fetching tokens:", error);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, debouncedSearchQuery, getCryptoPrice]);

  const updateTokensBalance = useCallback(async () => {
    setIsLoading(true);
    try {
      const updatedTokens = await Promise.all(
        tokens.map(async (item) => {
          try {
            const walletAddress =
              item.chainId === BTC_CHAIN_ID
                ? appInfo?.BtcWalletMnemonic.address
                : item.chainId === TRON_CHAIN_ID
                ? appInfo?.TronWalletMnemonic.address
                : appInfo?.EVMWalletMnemonic?.address;

            const balance = await evmCryptoBalanceCheck(
              item?.chainId,
              item?.address,
              walletAddress,
              item?.decimal
            );
            const _token = (
              item?.code.split(".")[0] ?? item?.code
            ).toUpperCase();
            return {
              ...item,
              balance: balance ?? 0,
              usd: (await getPrice(_token)) * balance ?? 0,
            };
          } catch (err) {
            console.error(`Error checking balance for ${item.symbol}:`, err);
            return { ...item, balance: 0 };
          }
        })
      );
      console.log("updated Tokens", updatedTokens);
      setUpdatedTokens(updatedTokens);
    } catch (e) {
      console.error("Error in evmCryptoBalanceCheck call:", e);
    } finally {
      setIsLoading(false);
    }
  }, [tokens, appInfo]);

  useEffect(() => {
    fetchAppDetails();
  }, [fetchAppDetails]);

  useEffect(() => {
    fetchTokens();
  }, [fetchTokens]);

  useEffect(() => {
    updateTokensBalance();
  }, [tokens, updateTokensBalance]);

  const handlePageChange = (pageNo) => {
    if (pageNo > 0 && pageNo <= totalPages) {
      setCurrentPage(pageNo);
    }
  };

  // Rahul

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const fetchTokensForTokenExistence = async (tokenId) => {
    // Fetch the tokens
    const response = await API.get(
      `${process.env.REACT_APP_API_URL}/token/list`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          pageNo: 1,
          limitVal: 100,
          search: "",
        },
      }
    );

    const tokens = response?.data?.data;

    // Check if the token exists
    const tokenExists = tokens.some((token) => token._id === tokenId);

    return tokenExists;
  };

  const handleSendNavigation = async (tokenId) => {
    try {
      const isTokenExists = await fetchTokensForTokenExistence(tokenId);
      if (isTokenExists) {
        // Navigate to the appropriate route if the token exists
        navigate(`/apps-send-withdraw/${appId}`, { state: { tokenId } });
      } else {
        // Show an error if the token does not exist
        errorSwal(
          "Token Not Found!",
          `Unfortunately, this token has been removed.`
        );
      }
      await fetchTokens();
    } catch (error) {
      console.error("Error fetching tokens:", error);
      errorSwal("Error", "Unable to fetch tokens. Please try again later.");
    }
  };

  const handleDepositNavigation = async (tokenId, tokenNetwork) => {
    try {
      // alert(`Token ${tokenId}`);
      setIsLoading(true);
      const isTokenExists = await fetchTokensForTokenExistence(tokenId);
      if (isTokenExists) {
        // Navigate to the appropriate route if the token exists
        navigate(`/apps-deposit-receive/${appId}`, {
          state: { tokenId, tokenNetwork },
        });
      } else {
        // Show an error if the token does not exist
        errorSwal(
          "Token Not Found!",
          `Unfortunately, this token has been removed.`
        );
      }
      await fetchTokens();
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching tokens:", error);
      errorSwal("Error", "Unable to fetch tokens. Please try again later.");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchApiKeys = async (appId) => {
    try {
      const response = await API.get(
        `${process.env.REACT_APP_API_URL}/apps/keys`,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            appId: appId,
          },
        }
      );

      if (response?.data) {
        setPublicKey(response?.data?.publicKey);
        setPrivateKey(response?.data?.privateKey);
      }
    } catch (error) {
      console.error("Error fetching API keys:", error);
    }
  };

  const handleApiKeyClick = (appId) => {
    fetchApiKeys(appId);
  };

  const handleCopyClick = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  console.log("updatedTokens : ", updatedTokens);

  return (
    <>
      {isLoading && <Loader />}
      <DashboardHeader appId={appInfo?._id} />
      <div className="site-bg">
        <div className="dashborad-content-bg">
          <aside className="sidebar">
            <Sidebar />
          </aside>
          <div className="dashborad-warper">
            <div className="transactions-list">
              <div className="dashborad-head">
                <div className="breadcrumb-menu">
                  <ul>
                    <li className="active">
                      <Link to="/apps">Apps</Link>
                    </li>
                    <li>
                      <a>&rarr; {appInfo?.name} Details</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="white-bg padd20 api-key-payment-box">
                <div className="api-key-payment-left">
                  <h3>{appInfo?.name}</h3>
                  <div className="table-action-btn api-key-btn">
                    <a
                      href=""
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                      onClick={() => handleApiKeyClick(appInfo?._id)}
                    >
                      <i className="ti ti-key" />
                      <span>API Key</span>
                    </a>
                  </div>
                </div>
                <div className="api-key-payment-right">
                  <a
                    className="btn"
                    onClick={() => {
                      if (appInfo?._id) {
                        navigate(`/apps-payment-links/${appId}`);
                      }
                    }}
                  >
                    <i className="ti ti-arrow-down-left" />
                    <span>Payment Link History</span>
                  </a>
                  <a
                    className="btn"
                    onClick={() => {
                      if (appInfo?._id) {
                        navigate(`/apps-wallet-transactions/${appId}`);
                      }
                    }}
                  >
                    <i className="ti ti-arrow-up-right" />
                    <span>Wallet History</span>
                  </a>
                </div>
              </div>
              <div className="white-bg my-apps-content padd20">
                <div className="search-filter-box">
                  <div className="dashborad-search-box">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by Blockchain Network"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                  {/* <div className="filter-box">
                    <select className="form-control">
                      <option>Filter</option>
                    </select>
                  </div> */}
                </div>
                <div className="table-box">
                  <table className="table">
                    <tbody>
                      <tr>
                        <th>Coin Name</th>
                        <th>Blockchain Network</th>
                        <th>Code</th>
                        <th>Balance</th>
                        <th>
                          <div className="value-box">
                            <span>Value</span>
                            <div className="usd-select">
                              <select
                                className="form-control"
                                onChange={(event) => {
                                  console.log(
                                    "Selected value is : ",
                                    event.target.value
                                  );
                                  setPriceIn(event.target.value);
                                }}
                              >
                                {currencyList &&
                                  currencyList.map((currency) => (
                                    <option
                                      key={currency.value}
                                      value={currency.value}
                                    >
                                      {currency.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </th>
                        <th>Commands</th>
                      </tr>
                      {updatedTokens.map((val, key) => (
                        <tr key={key}>
                          <td>{val?.symbol}</td>
                          <td>{val?.network}</td>
                          <td>{val?.code}</td>
                          <td>{formatNumber(Number(val?.balance), 8)}</td>
                          {/* <td>{formatNumber(val?.usd, 8)}</td> */}
                          <td>
                            {priceIn === "USD"
                              ? "$"
                              : priceIn === "INR"
                              ? "₹"
                              : "AU$"}{" "}
                            {formatNumber(val?.usd?.toPrecision(10), 5)}
                          </td>

                          <td>
                            <div className="option-box">
                              <div className="dropdown">
                                <button
                                  className="dropdown-toggle"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  Options
                                </button>
                                <ul className="dropdown-menu">
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleDepositNavigation(
                                          val._id,
                                          val.network
                                        )
                                      }
                                      className="dropdown-item"
                                    >
                                      Deposit/Receive
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() =>
                                        handleSendNavigation(val._id)
                                      }
                                      className="dropdown-item"
                                    >
                                      Send/Withdraw
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        if (appInfo?._id) {
                                          navigate(
                                            `/apps-payment-links/${appId}`
                                          );
                                        }
                                      }}
                                      className="dropdown-item"
                                    >
                                      Payment Link History
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        if (appInfo?._id) {
                                          navigate(
                                            `/apps-wallet-transactions/${appId}`
                                          );
                                        }
                                      }}
                                      className="dropdown-item"
                                    >
                                      Wallet History
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="pagination-box">
                  <ul>
                    <li>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage - 1);
                        }}
                        disabled={currentPage === 1}
                      >
                        <img src="images/left-arrow.png" alt="arrow" />
                      </a>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNo) => (
                      <li
                        key={pageNo + 1}
                        className={currentPage === pageNo + 1 ? "active" : ""}
                      >
                        <a
                          href=""
                          onClick={(e) => {
                            e.preventDefault();
                            handlePageChange(pageNo + 1);
                          }}
                        >
                          {pageNo + 1}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          handlePageChange(currentPage + 1);
                        }}
                        disabled={currentPage === totalPages}
                      >
                        <img src="images/right-arrow.png" alt="arrow" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* API Key */}
      <div
        className="modal fade"
        id="exampleModal2"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel2"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel1">
                API Key
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body api-key-modal">
              <div className="form-group app-id-box">
                <label>Api Key</label>
                <div className="form-group-inner">
                  <input
                    type="text"
                    className="form-control"
                    value={`${publicKey.slice(0, 8)}*****************`}
                    readOnly
                  />
                  <button
                    className="copy-code-btn"
                    onClick={() => handleCopyClick(publicKey)}
                  >
                    <i className="ti ti-copy" />
                  </button>
                </div>
              </div>
              <div className="form-group app-id-box">
                <label>Secret Key</label>
                <div className="form-group-inner">
                  <input
                    type="text"
                    className="form-control"
                    value={`${privateKey.slice(0, 8)}*****************`}
                    readOnly
                  />
                  <button
                    className="copy-code-btn"
                    onClick={() => handleCopyClick(privateKey)}
                  >
                    <i className="ti ti-copy" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppsDetail;
