import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Loader } from "../components/Loader";
import API from "../helper/AxiosInterceptor";

const Pricing = () => {
  const [pricingId1, setPricingId1] = useState("");
  const [heading1, setHeading1] = useState("");
  const [pricing1, setPricing1] = useState("");
  const [description1, setDescription1] = useState("");

  const [pricingId2, setPricingId2] = useState("");
  const [heading2, setHeading2] = useState("");
  const [pricing2, setPricing2] = useState("");
  const [description2, setDescription2] = useState("");

  const [pricingId3, setPricingId3] = useState("");
  const [heading3, setHeading3] = useState("");
  const [pricing3, setPricing3] = useState("");
  const [description3, setDescription3] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const fetchPages = async () => {
    try {
      setIsLoading(true);
      const response = await API.get(
        `${process.env.REACT_APP_API_URL}/pricing/list`
      )
        .then((res) => {
          setPricingId1(res?.data?.data[0]?._id);
          setHeading1(res?.data?.data[0]?.title);
          setPricing1(res?.data?.data[0]?.pricing);
          setDescription1(res?.data?.data[0]?.description);

          setPricingId2(res?.data?.data[1]?._id);
          setHeading2(res?.data?.data[1]?.title);
          setPricing2(res?.data?.data[1]?.pricing);
          setDescription2(res?.data?.data[1]?.description);

          setPricingId3(res?.data?.data[2]?._id);
          setHeading3(res?.data?.data[2]?.title);
          setPricing3(res?.data?.data[2]?.pricing);
          setDescription3(res?.data?.data[2]?.description);
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.error("Error fetching pages:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBuyNow = (pricingId) => {
    navigate(`/contact-us?pricingId=${pricingId}`);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Header />
      <div className="site-bg">
        <section className="inner-page-head">
          <div className="container">
            <h1>Pricing</h1>
            <ul>
              <li className="home">
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/pricing">&rarr; Pricing</Link>
              </li>
            </ul>
          </div>
        </section>
        <section className="priceing-list section-padding light-bg">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="price-box">
                  <div className="price-head">
                    <h5>{heading1}</h5>
                    <h3>{pricing1}</h3>
                  </div>

                  <div
                    className="price-des-list"
                    dangerouslySetInnerHTML={{ __html: description1 }}
                  />
                  <div className="buy-now-btn">
                    {/* <a className="btn">Buy Now</a> */}
                    <button
                      className="btn"
                      onClick={() => handleBuyNow(pricingId1)}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-box plan-active">
                  <div className="price-head">
                    <h5>{heading2}</h5>
                    <h3>{pricing2}</h3>
                  </div>

                  <div
                    className="price-des-list"
                    dangerouslySetInnerHTML={{ __html: description2 }}
                  />
                  <div className="buy-now-btn">
                    {/* <a className="btn">Buy Now</a> */}
                    <button
                      className="btn"
                      onClick={() => handleBuyNow(pricingId2)}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="price-box">
                  <div className="price-head">
                    <h5>{heading3}</h5>
                    <h3>{pricing3}</h3>
                  </div>

                  <div
                    className="price-des-list"
                    dangerouslySetInnerHTML={{ __html: description3 }}
                  />
                  <div className="buy-now-btn">
                    {/* <a className="btn">Buy Now</a> */}
                    <button
                      className="btn"
                      onClick={() => handleBuyNow(pricingId3)}
                    >
                      Buy Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
