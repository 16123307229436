import React from "react";
import {
  copyToClipboard,
  formatDate,
  formatNumber,
  trimAddress,
} from "../helper/helper";
import { IoCopyOutline } from "react-icons/io5";
import { getNetwork } from "../helper/evmHelper";

const TransactionsDataTable = ({
  transactions,
  isCode = false,
  isAmount = false,
  isReceivedAmount = false,
  isStatus = true,
  isInvoice = false,
  isTaxAmount = false,
  isTxType = false,
  isAmountAfterTax = false,
}) => {
  const classOfTable = {
    cursor: "pointer",
  };

  console.log("transactions", transactions);

  return (
    <div className="table-box">
      <table className="table">
        <thead>
          <tr>
            <th>SN</th>
            <th>Chain Id</th>
            {isTxType && <th>Transaction Type</th>}
            <th>Transaction Hash</th>
            <th>From</th>
            <th>To</th>
            <th>Symbol</th>
            {isAmount && <th>Amount</th>}
            {isReceivedAmount && <th>Received</th>}
            {isTaxAmount && <th>Taxes</th>}
            {isAmountAfterTax && <th>Excluding Tax</th>}
            {isCode && <th>Code</th>}
            <th>Time Stamp</th>
            {isStatus && <th>Status</th>}
            {isInvoice && <th>Invoice</th>}
          </tr>
        </thead>
        <tbody>
          {transactions?.length > 0 ? (
            transactions.map((data, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{data?.chainId}</td>
                {isTxType && <td>{data?.txType}</td>}
                <td>
                  <span
                    onClick={() => {
                      let explorerURL =
                        getNetwork(data?.chainId).explorerURL + data?.hash;
                      window.open(explorerURL ?? data?.linkURL);
                    }}
                    className="transaction-hash"
                  >
                    {trimAddress(data?.hash, 5, 8)}
                  </span>
                  {data?.hash ? (
                    <button
                      onClick={() => copyToClipboard(data?.hash)}
                      style={{
                        marginLeft: "1rem",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <IoCopyOutline />
                    </button>
                  ) : (
                    "-----------------------------"
                  )}
                </td>

                <td>
                  {trimAddress(data?.fromAddress, 4, 5)}
                  {data?.fromAddress ? (
                    <button
                      onClick={() => copyToClipboard(data?.fromAddress)}
                      style={{
                        marginLeft: "1rem",
                        border: "none",
                        background: "transparent",
                      }}
                    >
                      <IoCopyOutline />
                    </button>
                  ) : (
                    "------------------------"
                  )}
                </td>
                <td>
                  {trimAddress(data?.toAddress ?? data?.to, 4, 5)}
                  <button
                    onClick={() => copyToClipboard(data?.toAddress ?? data?.to)}
                    style={{
                      marginLeft: "1rem",
                      border: "none",
                      background: "transparent",
                    }}
                  >
                    <IoCopyOutline />
                  </button>
                </td>
                <td>{data?.symbol}</td>
                {isAmount && <td>{data?.amount}</td>}
                {isReceivedAmount && (
                  <td>{formatNumber(data?.recivedAmount ?? 0, 8)}</td>
                )}

                {isTaxAmount && <td>{formatNumber(data?.adminFee ?? 0, 8)}</td>}

                {isAmountAfterTax && (
                  <td>
                    {formatNumber(
                      data?.amountAfterTax ||
                        data?.amountAfterTax - data?.adminFee,
                      8
                    )}
                  </td>
                )}

                {isCode && <td>{data?.code} </td>}

                <td>{formatDate(data?.createdAt)}</td>

                {isStatus && (
                  <td>
                    <button
                      className={`status ${
                        data?.status.toUpperCase() === "SUCCESS"
                          ? "success"
                          : data?.status.toUpperCase() === "EXPIRED"
                          ? "error"
                          : "pending"
                      }`}
                      onClick={() => {
                        let explorerURL =
                          getNetwork(data?.chainId).explorerURL + data?.hash;
                        window.open(data?.linkURL ?? explorerURL);
                      }}
                    >
                      {data?.status}
                    </button>
                  </td>
                )}

                {isInvoice && (
                  <td
                    onClick={() => {
                      window.open(process.env.REACT_APP_API_URL + data?.file);
                    }}
                    className="table-invoice table-invoice-button"
                  >
                    {data?.invoice}
                    <i
                      className="ti ti-file-type-pdf"
                      style={{
                        fontSize: "24px",
                        marginRight: "10px",
                      }}
                    />
                  </td>
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="12">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    marginTop: "20px",
                  }}
                >
                  <p>Data not found</p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionsDataTable;
